nav {
  height: 30px;
  background-color: rgb(0,0,0,0.05);
  border-top: 1px solid rgb(0,0,0,0.1);
  border-bottom: 2px solid rgb(0,0,0,0.1);
  position: relative;
  box-sizing: border-box;
  
  #nav-bar {
    display: flex;
    height: 100%;;

    .nav-group-divider {
      height: 100%;
      width: 0;
      border-left: 1px solid rgb(0,0,0);
    }
  }

  #nav-drawer {
    display: none;
  }
}

.nav-link-group {
  width: 100% !important;

  .MuiAccordionDetails-root {
    padding: 0 0 0 6px;
    background-color: var(--tertiary-color);
    border-top: 1px solid black;
  }
}

.nav-link-option {
  background-color: white;
  border: none;
  border-left: 1px solid black;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  font-size: 16px;

  &:hover {
    cursor: pointer;
    background-color: rgb(248,248,248);
  }
  
  &:not(:last-child){
    border-bottom: 1px solid black;
  }
}

.selected-group {
  background-color: var(--primary-color) !important;
  color: white;
  font-weight: bold;

  .MuiAccordionDetails-root {
    border-top-color: white;
  }

  .MuiAccordionSummary-root {
    background-color: var(--primary-color);
    color: white;
    font-weight: bold;
  }
}

.selected-option {
  background-color: var(--secondary-color);
  color: white;
  font-weight: bold;
  
  &:hover {
    cursor: default;
    background-color: var(--secondary-color);
  }
}

.nav-drawer-no-subtabs {
  background-color: white;
  border: none;
  border-top: 1px solid black;
  height: 49px;
  font-size: 16px;
  padding-left: 52px;
  text-align: left;
  box-sizing: border-box;
  cursor: pointer;
}

.nav-shadow {
  position: fixed;
  top: 100;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: 3
}

@media (max-width: 1160px) {
  nav {
    height: 40px;
  }

  .nav-group {
    line-height: 1.1 !important;
  }

  .nav-shadow {
    top: 110px;
  }
}

@media (max-width: 760px) {
  .nav-group {
    font-size: 13px;
  }
}

@media (max-width: 730px) {
  nav {
    box-shadow: none;
    border-bottom: none;
    background-color: rgb(252,252,252);
  }
  
  #nav-bar {
    display: none !important;
  }

  #nav-drawer {
    display: inline-block !important;
  }

  .nav-shadow {
    top: 103px;
  }
}