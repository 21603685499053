footer {
  color: var(--primary-color);
  height: 100px;
  min-height: 100px;
  background-color: rgb(0,0,0,0.1);
  border-top: 2px solid rgb(150,150,150);
  padding: 6px 30px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}